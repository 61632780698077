import React from "react";

import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import BizSalaryLoanIcon from '../../../../../assets/img/more-for-you/kuda-bizSalaryLoan.icon.inline.svg'

export const moreForYou = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },
  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <BizSalaryLoanIcon />,
    title: "Salary Loan",
    subText: `Offer your staff loans with easy application, approval and disbursement through Kuda Business.`,
    linkTo: `/en-ng/business/salary-loan/`
  },
]